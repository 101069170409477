export let s_instance: AppTrigger = null;

export class AppTrigger {

    public static getInstance(): AppTrigger {
        if (s_instance == null) {
          s_instance = new AppTrigger();
        }
    
        return s_instance;
      }
    
      /**
       * クラスのインスタンス（シングルトン）を解放する。
       */
      public static releaseInstance(): void {
        if (s_instance != null) {
          s_instance.release();
        }
    
        s_instance = null;
      }

    public release(): void {

    }
    
    _modelId: number = 0;
    _backgroundId: number = 0;
    _expression_group: string = '';
    _motion_group: string = '';
    _change_expression: boolean = false;
    _change_motion: boolean = false;
    _change_model: boolean = false;
    _change_background: boolean = false;
    _wavFile: string = '';
    _change_wavFile: boolean = false;
    _noBackground: boolean = false;

    constructor() {
        this._modelId = 0;
        this._backgroundId = 1;
        this._expression_group = '';
        this._motion_group = '';
        this._change_expression = false;
        this._change_motion = false;
        this._change_model = false;
        this._change_background = false;
        this._wavFile = '';
        this._change_wavFile = false;
        this._noBackground = false;
    }
}