/**
 * Copyright(c) Live2D Inc. All rights reserved.
 *
 * Use of this source code is governed by the Live2D Open Software license
 * that can be found at https://www.live2d.com/eula/live2d-open-software-license-agreement_en.html.
 */

import { LAppDelegate } from './lappdelegate';
import * as LAppDefine from './lappdefine';
import { LAppGlManager } from './lappglmanager';

declare global {
  interface Window {
    setExpression: (expressionName: string) => void;
    setMotion: (motionName: string) => void;
    setSound: (wavFile: string) => void;
    setBackground: (backgroundId:number) => void;
    setNoBackground: (noBackground: boolean) => void;
    setModel: (modelId: number) => void;
    playSound: (soundUrl: string) => void;
    Flutter: any; // Declare the 'Flutter' property on the 'Window' interface
  }
}

const messageshead = [
  "Hello there!",
  "You clicked me!",
  "Nice to see you!",
  "How can I help?",
  "Have a great day!",
  "Keep smiling!",
  "You're awesome!",
  "Stay positive!",
  "Enjoy your time!",
  "Keep up the good work!"
];
const messagesbody = [
  "Oh! You startled me!",
  "Hehe, don't you think you're getting a bit too close?",
  "W-Wait... That was unexpected!",
  "You're so sweet! Thanks for the touch!",
  "Haha, I knew you'd come to me!",
  "Hm? Why did you touch me?",
  "Yay! I love it when you're close to me!",
  "Is that all you've got? You can do better!",
  "Keep going! You're doing great!",
  "Hehe, now it's my turn to surprise you!"
];

export function showDialog(message:string, x:number, y:number) {
  const dialog = document.getElementById('dialog');
  dialog.innerText = message;
  dialog.style.left = `${x}px`;
  dialog.style.top = `${y}px`;
  dialog.style.display = 'block';
  setTimeout(() => {
    dialog.style.display = 'none';
  }, 3000); // 3秒后自动消失
}

export function getRandomPosition() {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  // 生成靠近屏幕上方3/4处的随机坐标，并在上下左右各加减50的位置
  const x = Math.floor(screenWidth / 4 + Math.random() * screenWidth / 2) - 50 + Math.random() * 100;
  const y = Math.floor(screenHeight / 4) - 50 + Math.random() * 100;
  return { x, y };
}

export function getbodyRandomMessage() {
  const index = Math.floor(Math.random() * messagesbody.length);
  return messagesbody[index];
}

export function getheadRandomMessage() {
  const index = Math.floor(Math.random() * messageshead.length);
  return messageshead[index];
}

window.addEventListener('message', (event) => {
  if (window.Flutter) {
    // 确保只发送字符串类型的消息
    if (typeof event.data === 'string') {
      window.Flutter.postMessage(event.data);
    } else {
      window.Flutter.postMessage(JSON.stringify(event.data));
    }
  }
});

/**
 * ブラウザロード後の処理
 */
window.addEventListener(
  'load',
  (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const param1 = urlParams.get('param1');
    const param2 = urlParams.get('param2');
    console.log('Param1:', param1);
    console.log('Param2:', param2);
    // Initialize WebGL and create the application instance
    if (
      !LAppGlManager.getInstance() ||
      !LAppDelegate.getInstance().initialize()
    ) {
      return;
    }
    LAppDelegate.getInstance().setModelId(parseInt(param1));
    LAppDelegate.getInstance().setBackgroundId(parseInt(param2));
    LAppDelegate.getInstance().run();
  },
  { passive: true }
);

/**
 * 終了時の処理
 */
window.addEventListener(
  'beforeunload',
  (): void => LAppDelegate.releaseInstance(),
  { passive: true }
);

/**
 * Process when changing screen size.
 */
window.addEventListener(
  'resize',
  () => {
    //if (LAppDefine.CanvasSize === 'auto') {
    //  LAppDelegate.getInstance().onResize();
    //}
  },
  { passive: true }
);

// 设置表情
window.setExpression = (expressionName: string): void => {
  LAppDelegate.getInstance().setExpressionGroup(expressionName);
};

// 设置动作
window.setMotion = (motionName: string): void => {
  LAppDelegate.getInstance().setMotionGroup(motionName);
};

// 实时更新嘴型同步
window.setSound = (wavFile: string): void => {
  
  //LAppDelegate.getInstance().setWavFile('../../Resources/Haru/sounds/sanli_44100_16bit.wav')
  LAppDelegate.getInstance().setWavFile(wavFile);
};

// 设置背景
window.setBackground = (bgid: number): void => {
  LAppDelegate.getInstance().setBackgroundId(bgid);
};

// 设置背景
window.setModel = (mdid:number): void => {  
  LAppDelegate.getInstance().setModelId(mdid);
};

// 设置背景
window.setNoBackground = (noBackground: boolean): void => {
  LAppDelegate.getInstance().setNoBackground(noBackground);
};
